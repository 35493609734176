.StatusMessageTop {
  position: relative;
}

.StatusMessage {
  --main-color: var(--ion-color-danger);
  --contrast-color: white;
  display: grid;
  grid-template-columns: 28px 1fr;
  color: var(--main-color);
  border-radius: var(--border-radius-small);
  padding: 10px;
}
.StatusMessage.warning {
  --main-color: var(--ion-color-warning);
}
.StatusMessage.danger.bulky {
  background-color: rgba(255, 150, 150, 0.1);
}
.StatusMessage.bulky {
  padding: 20px;
}
.icon {
  position: relative;
  background-color: var(--main-color);
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.icon span {
  position: absolute;
  background-color: var(--contrast-color);
  height: 6px;
  width: 2px;
  left: 8px;
  top: calc(50% - 5px);
}
.icon span:after {
  content: '';
  position: absolute;
  background-color: var(--contrast-color);
  height: 2px;
  width: 2px;
  left: 0;
  top: calc(50% + 5px);
}
