/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'theme/grapes';


.templates-modal{
  --min-width: 66%;
  --min-height: 75%;
}

//#saved > .alert-wrapper {
//  z-index: 99999999999999999999999 !important;
//  //background-color: red;
//}
//#saved {
//  z-index: 99999999999999999999999 !important;
//  //background-color: red;
//}

/**
Hide certain elements during resizing due to grapes having a delay in repainting them during resizing, which gives a funny looking effect
 */
body.resizing-layouts {
  .gjs-highlighter,
  .gjs-toolbar,
  .gjs-badge {
    opacity: 0;
  }
}

body {
  .dhx_cal_light,
  .dhx_cal_cover {
    transition: opacity 300ms ease-in-out;
  }
  .dhx_cal_light {
    opacity: 1;
  }
}
body.backdrop-no-scroll {
  .dhx_cal_light,
  .dhx_cal_cover {
    transition: opacity 300ms ease-in-out;
    display: none !important;
    opacity: 0;
  }
}

.select-playable-content-modal {
  .popover-content {
    min-width: 600px;
  }
}

.popover-import-track {
  .popover-content {
    min-width: 400px;
  }
}

.alert-wrapper {
  // makes modals selectable
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.large-modal {
  .alert-wrapper {
    min-width: 650px;
  }
}

.alert-wrapper .alert-button-group {
  padding-bottom: 17px;
  padding-right: 17px;
}

.prettyTime {
  text-transform: none !important;
}

ion-searchbar {
  .searchbar-input-container {
    .searchbar-input {
      border-radius: 30px !important;
    }
  }
}
/* Editor */
.custom-checkbox-wrapper {
  display: flex;
  background-color: #444;
}
@media all and (max-width: 1680px) {
  .custom-checkbox-wrapper > label {
    margin-top: 5px;
  }
}
.custom-checkbox-wrapper span {
  padding: 0px 8px;
  font-size: 0.7rem;
}

/* Settings page */
.settings-segment-button {
  display: flex;
  flex-grow: 1;
}

/* Interactive mode panel && popup list */
.interactive-mode-panel {
  border: 1px solid #333;
  margin: 0.5rem;
  border-radius: 10px;
  padding: 10px;
  color: #ddd;
  > div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333;
    margin-bottom: 0.5rem;
  }
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;
      position: relative;
      &:hover {
        > div {
          display: block;
          width: 90px;
          background-color: #333;
          display: flex;
          justify-content: space-around;
          align-items: center;
          > ion-button:first-child {
            display: none;
          }
          ion-icon {
            cursor: pointer;
          }
        }
      }
      p {
        margin: 0.5rem 0;
        width: 100%;
        cursor: pointer;
        &.opened {
          font-weight: bold;
        }
      }
      > div {
        position: absolute;
        right: 0px;
        border-radius: 5px;
        transition: 200ms;
        overflow: hidden;
        width: 35px;
        height: 100%;
      }
    }
  }
}

/* Action list */
#custom-settings .gjs-trt-trait__wrp.hideTrait {
  display: none;
}

.gjs-field-interactive-mode-remove-action {
  width: 25%;
  margin: auto;
}
#gjs-sm-actions {
  .gjs-trt-trait__wrp {
    width: 100%;
  }
  .interactive-mode-actions-container {
    display: flex;
    .btn-add-action {
      width: 100%;
    }
    .btn-delete-action {
      width: 100%;
      margin: 5px;
    }
  }
  .trait-action-even {
    background-color: rgba(0, 0, 0, 0.2);
    p {
      background-color: #363636;
    }
  }
}

.filter-dropdown-container {
  position: relative;
  > p {
    background-color: #444;
    padding: 5px 0px;
    margin: 0px;
  }
  .tag-input {
    position: relative;
    .search {
      display: inline-block;
      width: 30%;
    }
    .list-container {
      width: 100%;
      position: absolute;
      left: 0%;
      top: 100%;
      background-color: #333;
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      z-index: 100;
      box-shadow: 2px 10px 10px #222;
      &.show {
        display: block;
      }
      li {
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        &:hover {
          background-color: #444;
        }
      }
    }
  }
  .filter-dropdown-tags {
    padding: 5px;
    > span {
      display: inline-block;
      padding: 5px;
      margin: 5px;
      background-color: #444;
      pointer-events: none;
      ion-icon {
        pointer-events: all;
      }
    }
    span::after {
      content: 'X';
      margin-left: 10px;
      pointer-events: all;
      cursor: pointer;
    }
  }
}

.full-screen-page-transition-out {
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}

.largeTextModal {
  --min-height:250px;
  --max-height:600px;
  --max-width: 600px;

  .alert-message {
    white-space: pre-line;
  }
}
