/* css only tooltip operated by the tip attribute */

[tip] {
  --tip-transition-animation-speed: 0.4s;
  z-index: 9999999999999;
  position: relative;
}
[tip]:after {
  content: '';
  opacity: 0;
  margin-top: 20px;
  z-index: 9999999999999;
  position: fixed;
}
[tip]:hover:after {
  transition: var(--tip-transition-animation-speed) opacity linear, var(--tip-transition-animation-speed) margin-top cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 1s;
  margin-top: 0;
  font-size: var(--ds-font-size);
  font-family: var(--ds-font-family);
  content: attr(tip);
  inline-size: max-content;
  //max-width: 180px;
  text-transform: none;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light-tint);
  transform: translateX(-50%) translateY(15px);
  opacity: 1;
  position: absolute;
  left: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: inline-table;
}
[tip]:before {
  position: absolute;
  left: 50%;
  content: '';
  opacity: 0;
  margin-bottom: -20px;
  z-index: 9999999999999;
}
[tip]:hover:before {
  transition: var(--tip-transition-animation-speed) opacity linear, var(--tip-transition-animation-speed) margin-bottom cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 1s;
  margin-bottom: 0;
  bottom: -15px;
  transform: translateX(-50%);
  opacity: 1;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--ion-color-dark);
}
[data-my-field] {
  --tip-transition-animation-speed: 0.4s;
  position: relative;
}
[data-my-field]:after {
  content: '';
  opacity: 0;
  margin-top: 20px;
  z-index: 9999999999999;
  position: fixed;
}
[data-my-field]:hover:after {
  transition: var(--tip-transition-animation-speed) opacity linear, var(--tip-transition-animation-speed) margin-top cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 1s;
  margin-top: 0;
  font-size: var(--ds-font-size);
  font-family: var(--ds-font-family);
  content: attr(tip);
  inline-size: max-content;
  //max-width: 180px;
  text-transform: none;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light-tint);
  transform: translateX(-50%) translateY(15px);
  opacity: 1;
  position: absolute;
  left: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: inline-table;
}
[data-my-field]:before {
  position: absolute;
  left: 50%;
  content: '';
  opacity: 0;
  margin-bottom: -20px;
  z-index: 9999999999999;
}
[data-my-field]:hover:before {
  transition: var(--tip-transition-animation-speed) opacity linear, var(--tip-transition-animation-speed) margin-bottom cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 1s;
  margin-bottom: 0;
  bottom: -15px;
  transform: translateX(-50%);
  opacity: 1;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--ion-color-dark);
}
