.ds-card {
  max-width: 400px;
  border-radius: calc(var(--ds-border-radius) - 1px);
}
.ds-card-header {
  padding-bottom: 8px;
}
.ds-card-title,
.ds-card-title span {
  font-size: var(--ds-font-size) !important;
  font-weight: 600;
  vertical-align: middle;
  color: var(--ion-color-primary);
}

.ds-card-icon {
  width: 24px;
  height: 24px;
  padding-right: 10px;
  vertical-align: middle;
}

.ds-card-text {
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: calc(var(--ds-font-size) - 2px) !important;
  color: #6e6b7b !important;
  padding-bottom: 12px;
}

.ds-card-icon-content {
  padding-left: 49px;
}
.ds-card-content {
  padding-left: 54px;
}
.ds-card-button {
  height: var(--ds-modal-button-height);
  //width: 35%;
  text-align: center;
  border: var(--ds-border-width) solid var(--ion-color-primary);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: calc(var(--ds-font-size) - 3px);
  font-weight: 500;
  color: var(--ion-color-primary);
  margin-right: 15px;
  --background: white;
  --background-hover: white;
  padding: 0;
}
.ds-card-button:hover {
  --opacity: 0.6;
  border-color: rgba(36, 168, 224, 0.6); // primary color with  0.7 opacity
}
.slide-header {
  background-color: var(--ion-color-light-tint);
  padding: 5px 20px;
  border-radius: var(--border-radius);
  margin-bottom: var(--vertical-gutter);
}
.slide-header h3 {
  font-weight: 600;
  font-size: var(--ds-font-size);
  color: #5e5873;
  margin: 20px 0;
}
.slide-header h4 {
  font-weight: 400;
  font-size: calc(var(--ds-font-size) - 2px);
  color: #6e6b7b;
  margin: 20px 0;
}

.ds-long-card {
  background-color: var(--ion-color-light-tint);
  padding: 5px 20px 5px 20px;
  border-radius: var(--border-radius);
  margin-bottom: 25px;
}
.ds-long-card-title {
  font-weight: 600;
  font-size: var(--ds-font-size);
  color: #5e5873;
  margin: 20px 0;
}

.ds-modal-card-title {
  font-size: calc(var(--ds-font-size) + 2px) !important;
  font-weight: 600;
  color: #5e5873;
  margin: 15px 0;
}
.ds-modal-card-content {
  padding-left: 51px;
}
.ds-modal-card-icon {
  padding-right: 2px;
  width: 24px;
  height: 24px;
}
.ds-card-text,
.ds-long-card-text {
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #6e6b7b !important;
  padding-bottom: 12px;
}
