:root {
  // Ionic Variables and Theming. For more info, please see:
  // http://ionicframework.com/docs/theming/
  // Palette variables

  /** Ionic CSS Variables **/
  /** primary **/
  --ion-color-primary: #25a9e0;
  --ion-color-primary-rgb: 37, 169, 224;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #25a9e0;
  --ion-color-primary-tint-more: #d9e9f4;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #aae2fa;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffc38c;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #fdf2e8;

  /** danger **/
  --ion-color-danger: #ea5455;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #404040;
  --ion-color-dark-rgb: 25, 25, 25;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #222428;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-tint-more: #6e6b7b;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 248, 248, 248;
  --ion-color-light-contrast: #5e5873;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #f3f2f7;
  --ion-color-light-shade-less: #f5f5f5;
  --ion-color-light-tint: #ffffff;

  --gjs-widget-font-family: Montserrat;
  --red: 'red';

  /** editor toolbar **/
  --tlb-front: 'Front';
  --tlb-back: 'Back';
  --tlb-fill: 'Fill';
  --tlb-clone: 'Clone';
  --tlb-delete: 'Delete';
  --tlb-move: 'Move';
  --tlb-rotate: 'Rotate';
  --tlb-font-size: '1.25rem';
  --tlb-addRow: 'Row';
  /** Scheduler */
  --event-shorter-than-appears: 'Event is shorter than it appears';

  --ion-background-color: #f8f8f8;

  --border-radius: 10px;
  --border-radius-small: 5px;
  --input-border-radius: var(--border-radius-small);
  --input-padding: 10px;
  --input-border-color: #d8d6de;

  --vertical-gutter: 16px;
  --horizontal-gutter: 20px;
  --horizontal-gap: 10px;

  --ds-font-family: Montserrat;
  --ion-font-family: Montserrat;
  --ds-font-size: 14px;
  --ds-icon-size: 21px;
  --ds-border-radius: 5px;
  --ds-border-width: 1px;
  --ds-main-header-height: 62px;
  --ds-modal-button-padding: 6px;
  --ds-modal-button-height: 30px;

  --gjs-input-font-size: 10px;
}
