.page-input-group {
  border-radius: var(--border-radius);
  background-color: #fff;
  padding: 20px;
}

.page-input-group-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  font-size: var(--ds-font-size);
}

.page-input-group h3 {
  font-size: 18px;
  margin: 20px 20px 20px 0;
}
.page-input-group h4 {
  color: #5e5873;
  font-weight: 500;
  font-size: 14px;
}
.page-input {
  width: 100%;
  font-weight: 500;
  color: #000;
  padding: 1% 0 1% 20px;
  font-size: var(--ds-font-size);
  border-radius: var(--input-border-radius);
  border: var(--ds-border-width) solid #d8d6de;

}
.page-input:hover:not(.disabled) {
  border: var(--ds-border-width) solid var(--ion-color-primary);
}
.page-input:focus-within:not(.disabled) {
  //todo
  outline: none;
  border: var(--ds-border-width) solid var(--ion-color-primary);
}
.ds-page-title {
  font-size: var(--ds-font-size) ;
  font-family: var(--ds-font-family) !important;
  color: #5e5873;
  font-weight: 500 !important;
  padding-left: 0 !important;
  padding-bottom: 6px;
}
.ds-modal-width {
  position: relative;
  left: 10%;
  width: 80%;
  font-size: var(--ds-font-size);
  font-family: Montserrat;

  ion-icon {
    color: var(--ion-color-primary);
  }
}
.ds-tabs-modal-width, .ds-tabs-modal-width {
  position: relative;
  left: 15%;
  width: 70%;

  -ms-overflow-style: none; /* Edge, Internet Explorer */
  //scrollbar-width: none; /* Firefox */
  //overflow-y: scroll;

  ion-card,
  ion-card-header,
  ion-card-content,
  ion-title {
    padding-left: 0 !important;
  }
}

// hides scrollbars while allowing to scroll
.ds-tabs-modal-width::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
// modal size
.modal-wrapper.sc-ion-modal-md {
  background: white;
  width: 36%;
  height: 70%;
}
.asset-selector-modal .modal-wrapper.sc-ion-modal-md {
  width: 35%;
  height: 75%;
}
.add-unit-modal .modal-wrapper.sc-ion-modal-md {
  width: 35%;
  height: 75%;
}

@media screen and (max-width: 1272px) {
  .modal-wrapper.sc-ion-modal-md {
    width: 52%;
    height: 77%;
  }
  .asset-selector-modal .modal-wrapper.sc-ion-modal-md {
    width: 59%;
    height: 95%;
  }
  .add-unit-modal .modal-wrapper.sc-ion-modal-md {
    width: 59%;
    height: 95%;
  }
}
@media screen and (max-width: 755px) {
  .modal-wrapper.sc-ion-modal-md {
    width: 95%;
    height: 95%;
  }
  .add-unit-modal .modal-wrapper.sc-ion-modal-md {
    width: 95%;
    height: 95%;
  }
  .asset-selector-modal .modal-wrapper.sc-ion-modal-md {
    width: 95%;
    height: 95%;
  }
}
.modal-input-group {
  border-radius: var(--border-radius);
  background-color: #fff; //todo
  margin-bottom: 20px;
  padding-top: 30px;
}
.modal-input-group-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15%;
  grid-column-gap: 3%;
  font-size: var(--ds-font-size) px;
}
.ds-modal-button-group {
  display: flex;
  align-items: center;
  font-size: calc(var(--ds-font-size) - 2px);
  font-family: var(--ds-font-family);
}
.modal-input-group-list h6 {
  font-size: calc(var(--ds-font-size) - 2px) !important;
  font-family: var(--ds-font-family) !important;
  color: #5e5873;
  font-weight: 400 !important;
  padding-left: 0 !important;
  margin-top: 0;
}
.ds-modal-title {
  font-size: calc(var(--ds-font-size) - 2px) !important;
  font-family: var(--ds-font-family) !important;
  color: #5e5873 !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
  padding-bottom: 6px;
}
.ds-modal-thick-title {
  font-size: calc(var(--ds-font-size) - 2px) !important;
  font-family: var(--ds-font-family) !important;
  color: #5e5873;
  font-weight: 600 !important;
  padding-left: 0 !important;
  padding-bottom: 6px;
}
.ds-modal-light-subtitle {
  font-size: calc(var(--ds-font-size) - 4px) !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
}
.ds-modal-subtitle {
  font-size: calc(var(--ds-font-size) - 4px) !important;
  font-weight: 700 !important;
  padding-left: 0 !important;
}
.modal-troubleshooting-title {
  font-size: 14px;
  font-weight: 600;
  padding-left: 0 !important;
  color: var(--ion-color-primary);
}
.modal-input-group-list p {
  color: #999;
  margin: 5px 0;
  //font-style: italic;
  font-size: 14px;
}
.sub-text {
  color: #b9b9c3;
  margin: 5px 0;
  display: block;
  //font-style: italic;
  font-size: 12px;
  font-weight: 400;
}
.modal-input-group-list input,
.modal-input-group-list ion-searchbar {
  //todo replace with class ds-modal-input
  font-size: 14px;
  padding-left: 10px;
  font-weight: 400;
  width: 100%;
  height: 30px;
  //border: 1px solid var(--ion-color-light-tint); //prevents items moving around on hover
}
.modal-input-group-list input:hover:not(.disabled) {
  //border: 1px solid ;
}
.ds-modal-input {
  font-size: calc(var(--ds-font-size) - 2px);
  color: #000000;
  //padding-left: 10px;
  font-weight: 400;
  //width: auto;
  height: var(--ds-modal-button-height);
  padding: 10px 15px;
  //border: 1px solid #D8D6DE;//prevents items moving around on hover

  border-radius: var(--input-border-radius);
  border: var(--ds-border-width) solid #d8d6de;
  width: 100%;
}
.ds-modal-input:focus-within:not(.disabled) {
  //todo
  outline: none;
  border: var(--ds-border-width) solid var(--ion-color-primary);
}

.ds-modal-input:hover:not(.disabled) {
  border: var(--ds-border-width) solid var(--ion-color-primary);
}
.ds-progress-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ds-progress-bar-message {
  font-size: 21px;
  font-weight: 500;
  float: left;
  padding: 10px 0;
}
.ds-progress-bar-icon {
  vertical-align: middle;
  font-size: 108px;
  padding: 20px 0;
}
ion-progress-bar {
  background-color: var(--ion-color-light-tint);
  margin-bottom: 1%;
  margin-top: 1%;
  height: 7px;
  border-radius: 5px;
}
#scanning-title {
  display: flex;
  justify-content: center;
  font-size: calc(var(--ds-font-size) + 4px);
  font-weight: 400;
  padding-top: 10%;
  padding-bottom: 5%;
}
ion-searchbar {
  border-radius: 25px !important;
  --border-radius: 25px !important;
  width: 100%;
  padding-left: 0;
  margin-bottom: 20px !important;
}
ion-searchbar .modal-searchbar {
  border-radius: 25px !important;
  --border-radius: 25px !important;
  width: 100%;
  padding-left: 0;
}

/**
 This function is actually used in the ion-title, or any tag
 */
.ds-tabs-modal-header {
  font-size: 18px;
  font-weight: 400;
  padding-left: 0;
}
.ds-modal-header {
  font-size: calc(var(--ds-font-size) + 2px);
  font-weight: 400;
  padding-left: 7%;
}

.ds-modal-footer {
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
}

.ds-modal-important-message {
  color: red;
  padding-top: 10px;
}
ion-content {
  --padding: 200px !important;
  --margin: 200px !important;
  // overwrite inline styles
  //--offset-bottom: auto!important;
  //--overflow: hidden;
  //overflow: auto;
  //&::-webkit-scrollbar {
  //  display: none;
  //}
}

.toggle {
  display: flex;
  align-items: center;

  ion-toggle {
    //position: absolute;
    //right: 0;
    //margin-right: 20px;
  }
}

.ds-modal-button {
  height: var(--ds-modal-button-height);
  width: 100%;
  margin-right: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: calc(var(--ds-font-size) - 3px) !important;
  font-weight: 500;
  background-color: var(--ion-color-primary);
  color: white;
  --background-hover: rgba(36, 168, 224, 0.6);
  padding: 0;
}
.ds-modal-button:hover {
  --opacity: 0.6;
  --background: rgba(36, 168, 224, 0.6); // primary color with  0.7 opacity
  //background-color: rgba(36, 168, 224, 0.6);
}

.ds-modal-secondary-button {
  height: var(--ds-modal-button-height);
  width: 100%;
  text-align: center;
  border: var(--ds-border-width) solid var(--ion-color-primary);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: calc(var(--ds-font-size) - 3px) !important;
  font-weight: 500;
  color: var(--ion-color-primary);
  margin-right: 15px;
  --background: white;
  --background-hover: white;
  padding: 0;
}
.ds-modal-secondary-button:hover {
  --opacity: 0.6;
  border-color: rgba(36, 168, 224, 0.6); // primary color with  0.7 opacity
}

.ds-modal-danger-button {
  height: var(--ds-modal-button-height);
  width: 100%;
  text-align: center;
  border: var(--ds-border-width) solid var(--ion-color-danger);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: calc(var(--ds-font-size) - 3px) !important;
  font-weight: 500;
  color: var(--ion-color-danger);
  margin-right: 15px;
  --background: white;
  --background-hover: white;
  padding: 0;
}
.ds-modal-danger-button:hover {
  --opacity: 0.6;
  border-color: rgba(234, 84, 85, 0.6); // primary color with  0.7 opacity
}

.modal-tabs {
  margin-top: 50px !important;
  margin-bottom: 30px !important;
  .modal-tab {
    border: var(--ds-border-width) solid var(--ion-color-primary);
    width: 10%;
    height: 105px;
    margin-right: 20px;
    color: var(--ion-color-primary);

    ion-icon {
      width: 29px;
      height: 29px;
      padding-right: 0;
      //color: var(--ion-color-primary);
    }
    ion-label {
      padding-top: 10px;
      font-size: 12px;
      font-weight: 400;
      //color: var(--ion-color-primary);
    }
  }
  .selected {
    color: white;
    background-color: var(--ion-color-primary);
  }
}

//Searchbar list
.timezone-results ion-list ion-label h2 {
  font-size: var(--ds-font-size);
}
.timezone-results ion-list ion-label h3 {
  font-size: calc(var(--ds-font-size) - 2px);
}
.timezone-results ion-list ion-label p {
  font-size: calc(var(--ds-font-size) - 2px);
}

.ds-modal-checkbox-icon {
  position: relative;
  margin-right: 5%;
  height: 30px;
  width: 30px;
}
