// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.
//ion-menu {
//  max-width: 200px !important;
//}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap'); */
@import 'src/theme/ds-modal';
@import 'src/theme/ds-card';
@import 'src/theme/scrollbar';
@import 'src/theme/tooltip';
@import 'src/theme/user-security';

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url('../assets/fonts/googlefonts/montserrat-v14-latin-300.woff2') format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url('../assets/fonts/googlefonts/montserrat-v14-latin-regular.woff2') format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url('../assets/fonts/googlefonts/montserrat-v14-latin-500.woff2') format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url('../assets/fonts/googlefonts/montserrat-v14-latin-600.woff2') format('woff2');
}

* {
  font-family: var(--ds-font-family);
  font-size: var(--ds-font-size);
}
ion-icon {
  font-size: var(--ds-icon-size);
  //height: 18px;
  //width: 18px;
}

body {
  background-color: #f8f8f8;
  font-family: var(--ds-font-family);
  font-size: var(--ds-font-size);
}

input,
textarea,
select {
  outline-color: var(--ion-color-primary);
}
ion-header {
  ion-buttons {
    font-size: var(--ds-font-size);
    font-weight: 500;
  }
  ion-title {
    font-size: 14px;
    font-weight: 500;
  }
}

.templates-modal {
  --min-width: 66%;
  --min-height: 75%;
}

.password-modal {
  .modal-wrapper {
    height: 250px;
  }
}

.update-apikey-modal {
  .modal-wrapper {
    height: 400px;
  }
}

.my-custom-menu {
  --width: 500px;
}
.page-body {
  /*overflow: hidden;*/ /* removed for user edit page, not sure if thats going to cause a problem else where */
  padding-bottom: 60px;
}
#main-content .page-body:not(.full-view),
#main-content .header-md:not(.full-view) {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 25px !important;
}

.icon-vertical-align {
  vertical-align: middle;
  ion-icon {
    vertical-align: middle;
  }
}
.ds-side-menu {
  font-size: var(--ds-font-size);

  ion-item {
    padding-bottom: 5px;
  }
  ion-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

$max-sidebar-width: 250px;

.hide {
  display: none;
}

.show {
  display: inline-block;
}
.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: $max-sidebar-width;
  border-right: none;
}

// todo this was on ion-split-pane. Removed by Eliran because it broke the side menu in smaller windows
.split-pane-container {
  --side-max-width: $max-sidebar-width;
  --max-width: $max-sidebar-width;
  --side-width: $max-sidebar-width;

  ion-menu {
    --max-width: $max-sidebar-width;
    --width: $max-sidebar-width;
  }
}

.center-content {
  display: flex;
  justify-content: center;
}
.clickable-list-item {
  cursor: pointer;
}

.keyword-dictionary-modal .modal-wrapper {
  width: 800px;
}
.event-editor-modal .modal-wrapper {
  width: 800px;
  height: 680px;
}

.disabled {
  opacity: 0.7;
}

.disabled:hover {
  cursor: default;
}

.syncLoadingSpinner {
  padding: 5px;
  transform: scale(1.3);
  animation-name: syncLoadingSpinner;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.syncLoadingSpinner.disabled {
  display: none;
}
@keyframes syncLoadingSpinner {
  from {
    transform: rotate(0deg) scale(1.3);
  }
  to {
    transform: rotate(-360deg) scale(1.3);
  }
}

#paneContent {
  background-color: var(--ion-color-light);
}
#paneContent .ion-page:not(app-login):not(app-editor):not(app-object-schedule) {
  padding: 40px 60px;
}

ion-menu {
  --ion-background-color: #fff;
  padding-left:15px;
  margin-right:15px;
  border: 0;
}

.header-md:after {
  display: none;
}
ion-card {
  --background: var(--ion-color-light-tint) !important;
  box-shadow: none !important;
  padding: 10px;
  border-radius: 5px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 15px !important;
}
ion-card .card-tip {
  margin-bottom: 15px;
}
.ion-page:not(app-login):not(ion-app):not(.full-view) {
  ion-header:not(.full-view) ion-toolbar {
    border-radius: 5px;
  }
}
.gutter-bottom {
  margin-bottom: var(--vertical-gutter);
}
.no-gutter-bottom {
  margin-bottom: 0;
}
.gutter-top {
  margin-top: var(--vertical-gutter);
}
.no-gutter-top {
  margin-top: 0;
}
ion-modal ion-content:not(.default-segments),
.page-body:not(.default-segments) {
  ion-segment {
    display: block !important;
    text-align: left !important;
    margin-bottom: var(--vertical-gutter);
    font-weight: 500;
    color: var(--ion-color-primary);
  }
  ion-segment-button {
    display: inline-block !important;
    --background: var(--ion-color-light-tint);
    --background-checked: var(--ion-color-light-tint);
    --indicator-color: transparent !important;
    --indicator-color-checked: transparent !important;
    width: 285px;
    height: 84px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 5px !important;
    margin: 0 10px 0 0;
    border: var(--ds-border-width) solid transparent;
    color: var(--ion-color-primary);
    ion-icon {
      width: 24px;
      height: 24px;
      padding-top: 10px;
      padding-right: 10px;
    }
    ion-label {
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      font-weight: 500;
    }
  }

  ion-segment-button.segment-button-checked {
    border: var(--ds-border-width) solid var(--ion-color-primary);
  }
  ion-segment.compact {
    display: flex !important;
    justify-content: flex-start;
  }
  ion-segment.compact ion-segment-button {
    min-width: auto;
  }
  ion-modal {
    ion-segment-button {
      min-width: 130px;
    }
  }
}
ion-modal ion-content:not(.default-segments) {
  ion-segment {
    display: flex !important;
    justify-content: flex-start;
  }
  ion-segment ion-segment-button {
    min-width: auto;
  }
}
.modal-wrapper {
  border-radius: 5px !important;
}
ion-modal {
  ion-header {
    ion-toolbar:not(.no-theme) {
      --background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      border-radius: 0 !important;
    }
  }
  ion-content,
  ion-footer {
    ion-toolbar:not(.no-theme) {
      --background: var(--ion-color-light-tint);
    }
  }
  ion-content {
    --background: var(--ion-color-light-tint) !important;
  }
  ion-content > * {
    //padding-left: 40px !important;
    //padding-right: 40px !important;
  }
}

ion-label[position='stacked'] {
  font-size: 18px !important;
}
ion-item {
  --background: var(--ion-color-light-tint);
  --border-radius: var(--ds-border-radius);
}
ion-item.no-padding {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}
ion-item-divider {
  --background: var(--ion-color-light-tint);
}
ion-menu-toggle {
  ion-item.is-active {
    --background: var(--ion-color-primary);
    --background-hover: var(--ion-color-primary);
    --color: var(--ion-color-light);
    ion-icon {
      color: var(--ion-color-light);
    }
  }
}

.list-md {
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
}
ion-grid {
  padding: 0;
}
ion-col {
  padding: 0;
}
.toolbar-background {
  background-color: transparent !important;
}

ion-button {
  --box-shadow: none;
  font-weight: 400;
  margin: 0;
  --padding-bottom: 13px;
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 13px;
}

.bordered-button {
  border: var(--ds-border-width) solid var(--ion-color-primary);
  background-color: transparent;
  border-radius: var(--input-border-radius);
  font-weight: 500;
}
.bordered-button.slim {
  --padding-start: 6px;
  --padding-end: 6px;
}

.profile-section {
  outline: none;
  display: inline-block;
  --padding-start: 0;
  --padding-end: 0;
  text-align: right;
  height: 60px;
  text-transform: none;
  width: 100%;
}
.profile-section .inner-button {
  text-align: right;
  width: 100%;
  line-height: 60px;
  display: grid;
  grid-template-columns: 1fr 60px;
}
.profile-section-text {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  margin-right: 12px;
  padding: 12px 0;
}
.profile-name {
  font-weight: 600;
  line-height: 20px;
  font-size: var(--ds-font-size);
  max-width: 196px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-name-label {
  line-height: 20px;
  font-weight: 400;
  font-size: calc(var(--ds-font-size) - 2px);
}
/* badge used in table lists, etc to indicate that the row entity is active or deactivated */
.status-badge {
  border-radius: 50px;
  max-width: 145px;
  background-color: #ffe9e9;
  color: #ad1519;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.status-badge.active {
  background-color: #e5f8ed;
  color: #28c76f;
}

.fake-page-input,
.input-frame,
.page-input-group input,
.page-input-group select,
.page-input-group textarea {
  border-radius: var(--input-border-radius);
  border: var(--ds-border-width) solid #d8d6de;
  width: 100%;
  padding: 10px 15px;
}

.fake-page-input {
  user-select: none;
}
.input-padding {
  padding: 10px 15px;
}

.page-line-separator {
  margin: 60px 0 40px 0;
  border-top: var(--ds-border-width) solid #ebe9f1;
}

.page-status-message {
  border-radius: var(--border-radius-small);
  background-color: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
  padding: 10px 15px;
  font-size: 15px;
}

ion-popover {

  .popover-viewport {

    border-radius: 6px;

    ion-list {
      padding-bottom: 10px;
      padding-top: 10px;
      background: white;
    }

    ion-item {
      font-size: calc(var(--ds-font-size) - 2px);
      background: white;
      height: 42px;
      border-radius: 0;
    }

    ion-icon {
      padding: 10px;
      align-self: center;
      color: var(--ion-color-medium) !important;
    }

    img {
      margin-left: 10px;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
}

.ds-button {
  height: 35px;
  border-radius: 5px;
  font-size: calc(var(--ds-font-size) - 2px);
  font-weight: 500;
  background-color: var(--ion-color-primary);
  color: white;
  margin-left: 15px;
}
.ds-button:hover {
  --opacity: 0.6;
  --background: rgba(36, 168, 224, 0.6); // primary color with  0.7 opacity
}
.ds-secondary-button {
  height: 35px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-primary);
  margin-left: 15px;
  --background: white;
  --background-hover: white;
  border: var(--ds-border-width) solid var(--ion-color-primary);
  box-sizing: border-box;
}
.ds-secondary-button:hover {
  --opacity: 0.6;
  border-color: rgba(36, 168, 224, 0.6); // primary color with  0.7 opacity
}

ion-item {
  border: none !important;
}
ion-list {
  border: none !important;
}

.ds-page-buttons {
  display: inline-block;
  float: right;
  position: relative;
  padding-top: 2%;
}

.ds-button-icon {
  width: 15px;
  height: 15px;
  padding-right: 6px;
}

.remove-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.url-input-trait.url-error {
  box-shadow: 0 0 2px #ec7c7c;
  transition: 1s;
}
.url-input-trait.url-success {
  box-shadow: 0 0 2px #73eb79;
  transition: 1s;
}
.url-input-trait.url-no-access {
  box-shadow: 0 0 2px #dfeb73;
  transition: 1s;
}

.select-player-modal {
  --width:500px;
  --max-width: 95%;
}

@media all and (min-width: 1000px) {
  ion-router-outlet .ion-page {
    margin-right:15px;

    ion-header {
      margin-top:15px;
    }
  }
}

ion-modal ion-header {
  margin-bottom: 0 !important;
}

.slide-preview-modal{
  --width:1000px;
  --max-width: 80%;
  --height:1000px;
  --max-height: 80%;
}

@media all and (max-width: 991px) {
  .page-body {
    padding-left:10px;
    padding-right: 10px;
  }
}

@media all and (max-width: 1200px) {
  .non-mobile-feature {
    display: none !important;
  }
}

@media all and (max-width: 650px) {
  ion-segment {
    white-space: nowrap;
    overflow-x: scroll;
  }
}

.big-table big-table-row:nth-child(even) .row {
  background-color: #f8f8f8;
}

.skeleton-table {
  ion-row {
    margin: 10px 0px;
  }
  ion-col {
    padding:10px 20px;

    &:last-of-type {
      ion-skeleton-text {
        float: right;
      }

    }
  }
}
.popover-wrapper {
  z-index: 9999999 !important;
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}


.video-format-container {
  width:100%;
  height:100%;
  background-color:#000;
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  overflow:hidden;
}
.video-format-container .filename {
  width: 100%;
  height: 60px;
  max-height: 100%;
  position: absolute;
  font-size: 3em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: sans-serif;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  background-color: rgba(255,255,255,0.6);
  color: #000;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.video-format-container .filename span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
