@font-face {
  font-family: 'Ionicons';
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1');
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix') format('embedded-opentype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1') format('truetype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1') format('woff'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

// removes empty space on the right of the canvas
.gjs-editor > .gjs-cv-canvas {
  width: 100%;
}
.gjs-pn-panel.gjs-pn-views-container {
  width: 0;
}

// aligns top panel buttons to the right
.gjs-pn-panels > .gjs-pn-options {
  right: 0;
}

// moves entire editorThree to the right
div.digisign.gjs-editor-cont {
  float: right;
}

.column {
  min-height: 75px;
  flex-grow: 1;
  flex-basis: 100%;
  overflow: auto;
}

.gjs-block-text {
  font-size: 13px;
  margin-bottom: 0;
  font-family: Helvetica, sans-serif !important;
}

#blocks .gjs-block {
  cursor: grab;
}
#blocks .gjs-block:active {
  cursor: grabbing;
}

// 16:9 chesky

.digisign {
  .gjs-sm-title {
    padding: 10px 25px !important;
  }
  .gjs-sm-properties {
    padding: 10px 15px;

    .gjs-sm-property {
      padding: 5px 14px;

      .gjs-sm-label {
        margin: 5px 5px 8px 0;
      }
    }
  }

  #blocks .gjs-block {
    font-family: 'Times New Roman', sans-serif;
  }

  .gjs-cv-canvas {
    width: 100%;

    .gjs-cv-canvas__frames {
      .gjs-vibe {
        font-size: 13px;
        color: blue;
        margin-bottom: 0;
        font-family: Helvetica, sans-serif !important;
      }
      .gjs-frame {
        bottom: auto;
        width: var(--slide-dimension-x);
        height: var(--slide-dimension-y);
        transform-origin: 0 0;
        right: auto;
        left: 0;
        }
      }

    .gjs-cv-canvas__tools {
      #gjs-tools {
        transform-origin: 0 0;

        // widget toolbar
        .gjs-toolbar {
          [toolbartip] {
            --tip-transition-animation-speed: 0.4s;
            position: relative;
          }
          [toolbartip]:after {
            content: '';
            opacity: 0;
            margin-top: 46px;
            z-index: 9999999999999;
            position: fixed;
          }
          [toolbartip]:hover:after {
            transition: var(--tip-transition-animation-speed) opacity linear, var(--tip-transition-animation-speed) margin-top cubic-bezier(0.16, 1, 0.3, 1);
            transition-delay: 1s;
            margin-top: 26px;
            font-size: 22px;
            font-family: var(--ds-font-family);
            content: attr(toolbartip);
            text-transform: none;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 10px 15px;
            background-color: var(--ion-color-light);
            color: black;
            transform: translateX(-50%) translateY(15px);
            opacity: 1;
            position: absolute;
            left: 50%;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          }
          [toolbartip]:before {
            position: absolute;
            left: 50%;
            content: '';
            opacity: 0;
            margin-bottom: -20px;
            //background-color: var(--ion-color-light);
            z-index: 9999999999999;
          }
          [toolbartip]:hover:before {
            transition: var(--tip-transition-animation-speed) opacity linear, var(--tip-transition-animation-speed) margin-bottom cubic-bezier(0.16, 1, 0.3, 1);
            transition-delay: 1s;
            margin-bottom: 0;
            bottom: -27px;
            transform: translateX(-50%);
            opacity: 1;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid var(--ion-color-light);
          }
          .gjs-toolbar-item {
            width: 85px;
            height: 30px;
            padding: 15px 7px;
            font-size: var(--ds-font-size);
            text-align: center;
          }

          .toolbar-item-icon-move {
            background-position: center !important;
            background: url('assets/icon/editor/move.svg') no-repeat;
            background-size: contain;
            margin-top: 20px;
          }

          .toolbar-item-icon-toFront {
            background-position: center !important;
            background: url('assets/icon/editor/to_front.svg') no-repeat;
            background-size: contain;
            margin-top: 20px;
          }
          .toolbar-item-icon-toBack {
            background-position: center !important;
            background: url('assets/icon/editor/to_back.svg') no-repeat;
            //transform: rotate(180deg);
            background-size: contain;
            margin-top: 20px;
          }
          .toolbar-item-icon-fill {
            background-position: center !important;
            background: url('assets/icon/editor/fill.svg') no-repeat;
            background-size: contain;
            margin-top: 20px;
          }
          .toolbar-item-icon-clone {
            background-position: center !important;
            background: url('assets/icon/editor/clone.svg') no-repeat;
            background-size: contain;
            margin-top: 20px;
          }
          .toolbar-item-icon-rotate {
            background-position: center !important;
            background: url('assets/icon/editor/rotate.svg') no-repeat;
            background-size: contain;
            margin-top: 20px;
          }
          .toolbar-item-icon-delete {
            background-position: center !important;
            background: url('assets/icon/editor/delete.svg') no-repeat;
            background-size: contain;
            margin-top: 20px;
          }
          .fa-hand-rock-o.gjs-toolbar-item:after {
            display: block;
            content: var(--tlb-move);
            font-size: var(--tlb-font-size);
            padding-top: 10px;
          }
          .fa-plus.gjs-toolbar-item:after {
            display: block;
            content: var(--tlb-addRow);
            font-size: var(--tlb-font-size);
            padding-top: 10px;
          }
        }
        .gjs-resizer {
          .gjs-resizer-h {
            width: 15px;
            height: 15px;
          }
        }
        .gjs-badge {
          font-size: 18px;
        }
        .gjs-rte-toolbar {
          .gjs-rte-action {
            min-width: 36px;
            min-height: 36px;
            b,
            i,
            u,
            strike,
            span {
              font-size: 1.2rem;
            }
          }
        }
        .gjs-guides {
          font-size: 1.25rem;
          .gjs-guide-info__x {
            .gjs-danger-bg {
              width: 3px;
            }
          }
          .gjs-guide-info__y {
            .gjs-danger-bg {
              height: 3px;
            }
          }
        }
      }
    }
    .gjs-block-label {
      font-family: var(--ds-font-family);
    }
  }

  // custom code modal save button
  .gjs-mdl-dialog .gjs-mdl-content > #gjs-mdl-c > .gjs-custom-code > .gjs-btn-import__custom-code {
    margin-top: 7px;
    float: right;
    height: 30px;
    width: 50px;
  }

}
